import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../Layouts/Layout'
import PageContainer from '../../components/PageContainer/PageContainer'
import { TagLink } from '../../modules/Tags/Tags'
import Title from '../../components/Title/Title'

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <Helmet title={`Tags | ${title}`} />
    <Title>Tags</Title>
    <PageContainer>
      <ul>
        {group.map(tag => {
          const { fieldValue, totalCount } = tag
          return (
            <li key={fieldValue}>
              <TagLink to={`/tags/${kebabCase(fieldValue)}/`}>
                {fieldValue} ({totalCount})
              </TagLink>
            </li>
          )
        })}
      </ul>
    </PageContainer>
  </Layout>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.objectOf(PropTypes.any),
    site: PropTypes.shape({
      siteMetadata: PropTypes.string,
    }),
  }).isRequired,
}

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
